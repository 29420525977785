import React from "react"
import { Link } from "gatsby"
import { notifications, notificationsMini, play, sms, push } from "../assets"
import "./main.css"

const NewFine = () => {
  return (
    <div className="flex pt6-l pl6-l pl5-m pl4 pr6-l pr3-m pr2 pb6-ns ">
      <div className="flex-l dn">
        <img
          alt="узнать о новом штрафе ГИБДД бесплатно по смс"
          title="уведомление о штрафе ГИБДД на экране смартфона"
          src={notifications}
          className="flex-ns mb0  image__size"
        />
      </div>
      <div className="flex flex-column w-60-l w-100 ml6-l f4-ns f5 pt5 lh-copy">
        <h1 className="flex-wrap w-100-ns w-80 f1-l f2-m f3 ipad__font__header b mb4">
          Как узнавать о новых предписаниях вовремя?
        </h1>
        <Link className=" flex link blue f4-ns f5 ipad__font__text items-center">
          <img alt="play" title="play" src={play} className=" w2 h2 mr3 mb0" />
          <span>Видеоинструкция как включить уведомления</span>
        </Link>
        <p className="mt4 ipad__font__text">
          У водителей не всегда есть возможность заходить в приложение и проверять,
          не появился ли новый штраф. Okauto будет оповещать вас о новых предписаниях, 
          чтобы вы успели оплатить их, воспользовавшись скидкой 50%.
        </p>
        <img
          alt="notificationsMini"
          title="notificationsMini"
          src={notificationsMini}
          className="dn-l  mb-0"
        />
        <div className="flex-ns mt4">
          <div className="dib mr5">
            <img
              alt="push"
              title="push"
              src={push}
              className="main__pages__icons"
            />
            <h2 className="f3-l f4-m f5 ">Push-уведомления </h2>
            <p className="gray ipad__font__citation">
              Узнавайте о штрафах первыми из push-уведомлений с помощью 
              сервиса оповещения OKauto.
            </p>
          </div>
          <div className="dib">
            <img
              alt="sms"
              title="sms"
              src={sms}
              className="main__pages__icons"
            />
            <h2 className="f3-l f4-m f5 ">SMS-уведомления </h2>
            <p className="gray ipad__font__citation">
              Авторизируйтесь через номер своего мобильного телефона, 
              чтобы получать уведомления о новых штрафах даже там, 
              где нет интернет покрытия.
              <br/>
              <br/>
              И не беспокойтесь о штрафах — доверьте это нам.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewFine
